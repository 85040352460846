import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import { throttle } from 'lodash';

import { useAppSelector, useAppDispatch } from 'utilities/redux/reduxHooks';
import { setVisibility, selectVisibility } from 'utilities/redux/filtersVisibilitySlice';

import { ContentView } from './view';

export const Content = () => {
    const data = useStaticQuery(graphql`
        query GetCertificationsContentData {
            allCertifications(filter: {
                id: {
                    ne: "dummy"
                }
            }) {
                nodes {
                    data {
                        name {
                            pl
                        }
                        brand {
                            pl
                        }
                        type {
                            pl
                        }
                        cert_file
                    }
                }
            }
        }
    `);
    const types = Array.from(new Set(data?.allCertifications?.nodes[0]?.data?.filter((currentValue) => (currentValue.type.pl != null)).map((currentValue) => (currentValue.type.pl)))).sort();
    const initialFilters = {
        name: '',
        brands: new Set(),
        types: new Set()
    };
    const [filters, setFilters] = useState(initialFilters);

    const areFiltersVisible = useAppSelector(selectVisibility);
    const dispatch = useAppDispatch();

    const setFiltersVisibility = (payload = undefined) => {
        dispatch(setVisibility(payload));
    };

    const setFilter = (action, key, value) => {
        setFilters((previousFilters) => {
            let filter;

            switch (action) {
                case 'set':
                    filter = ((typeof previousFilters[key]) === 'string')
                        ? (value)
                        : (new Set([...previousFilters[key], value]));
                    break;
                case 'unset':
                    filter = ((typeof previousFilters[key]) === 'string')
                        ? ('')
                        : (new Set([...previousFilters[key]].filter((currentValue) => (currentValue !== value))));
                    break;
            }

            return ({
                ...previousFilters,
                [key]: filter
            });
        });
    };

    const filterCertifications = () => (
        data.allCertifications.nodes[0].data.filter((currentValue) => (
            (!filters.name || currentValue.name.pl.toLowerCase().includes(filters.name.toLowerCase()))
            && (!filters.brands.size || filters.brands.has(currentValue.brand.pl))
            && (!filters.types.size || filters.types.has(currentValue.type.pl))
        ))
    );

    const clearFilters = () => {
        setFilters(initialFilters);
    };

    const toggleFiltersVisibility = () => {
        setFiltersVisibility();
    };

    useEffect(() => {
        const handleResize = throttle(() => {
            if (window.matchMedia('(min-width: 768px)').matches) {
                setFiltersVisibility(false);
            }
        }, 100);

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (areFiltersVisible) {
            disableBodyScroll();
        } else {
            enableBodyScroll();
        }
    }, [areFiltersVisible]);

    return (
        <ContentView
            certifications={ filterCertifications() }
            types={ types }
            filters={ filters }
            areFiltersVisible={ areFiltersVisible }
            areBadgesVisible={ Boolean(filters.name || filters.brands.size || filters.types.size) }
            setFilter={ setFilter }
            clearFilters={ clearFilters }
            toggleFiltersVisibility={ toggleFiltersVisibility }
        />
    );
};