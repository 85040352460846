import React from 'react';
import PropTypes from 'prop-types';

import { Field } from '../../field/component';

import baranskiPremium from '../../../../../assets/images/baranski-premium.svg';
import baranskiOptimo from '../../../../../assets/images/baranski-optimo.svg';
import baranskiSmart from '../../../../../assets/images/baranski-smart.svg';

export const BrandsView = ({ filters, setFilter }) => (
    <>
        <h4 className="filter__headline">Marka</h4>
        <Field
            name="Barański Premium"
            type="checkbox"
            logo={ baranskiPremium }
            filter="brands"
            properties={ filters }
            setFilter={ setFilter }
        />
        <Field
            name="Barański Optimo"
            type="checkbox"
            logo={ baranskiOptimo }
            filter="brands"
            properties={ filters }
            setFilter={ setFilter }
        />
        <Field
            name="Barański Smart"
            type="checkbox"
            logo={ baranskiSmart }
            filter="brands"
            properties={ filters }
            setFilter={ setFilter }
        />
    </>
);

BrandsView.propTypes = {
    filters: PropTypes.instanceOf(Object).isRequired,
    setFilter: PropTypes.func.isRequired
};