import React from 'react';
import PropTypes from 'prop-types';

import { FiltersView } from '../../../../utilities/components/filters/filters/component/view';
import { Field } from '../../../../utilities/components/filters/field/component';
import { BrandsView } from '../../../../utilities/components/filters/brands/component/view';
import { Filter } from '../../../../utilities/components/filters/filter/component';
import { ControlsView } from '../../../../utilities/components/filters/controls/component/view';

import './view.scss';

export const ContentView = ({
    certifications,
    types,
    filters,
    areFiltersVisible,
    areBadgesVisible,
    setFilter,
    clearFilters,
    toggleFiltersVisibility
}) => (
    <section className="content">
        <FiltersView
            areFiltersVisible={ areFiltersVisible }
            areBadgesVisible={ areBadgesVisible }
            clearFilters={ clearFilters }
            toggleFiltersVisibility={ toggleFiltersVisibility }
        >
            <Field
                name="name"
                type="search"
                placeholder="Szukaj..."
                value={ filters.name }
                filter="name"
                setValue={ setFilter }
            />
            <BrandsView filters={ filters?.brands } setFilter={ setFilter } />
            <Filter
                headline="Rodzaj certyfikatu"
                options={ types }
                filter="types"
                properties={ filters.types }
                setFilter={ setFilter }
            />
        </FiltersView>
        <div className="content__container">
            <ControlsView
                headline="Certyfikaty:"
                filters={ filters }
                areBadgesVisible={ areBadgesVisible }
                setFilter={ setFilter }
                clearFilters={ clearFilters }
                toggleFiltersVisibility={ toggleFiltersVisibility }
            />
            <div className="content__list">
                {
                    certifications.map((currentValue, index) => (
                        <a
                            className="list__item"
                            href={ `${process.env.GATSBY_URL}/${currentValue.cert_file}` }
                            target="_blank"
                            rel="noreferrer"
                            key={ index }
                        >
                            <p className="item__name">{ currentValue.name.pl }</p>
                            <div className="item__button">
                                Pobierz
                            </div>
                        </a>
                    ))
                }
            </div>
        </div>
    </section>
);

ContentView.propTypes = {
    certifications: PropTypes.instanceOf(Array).isRequired,
    types: PropTypes.instanceOf(Array).isRequired,
    filters: PropTypes.instanceOf(Object).isRequired,
    areFiltersVisible: PropTypes.bool.isRequired,
    areBadgesVisible: PropTypes.bool.isRequired,
    setFilter: PropTypes.func.isRequired,
    clearFilters: PropTypes.func.isRequired,
    toggleFiltersVisibility: PropTypes.func.isRequired
};